import { useAppSelector } from 'store/store'
import { useDispatch } from 'react-redux'
import { useGetActiveLoanQuery, useGetLoanDetailMutation } from 'api/services/loan/serviceLoan'
import { useGetCustomerQuery } from 'api/services/customer/serviceCustomer'
import {
    useCancelDeferralOrderMutation,
    useCreateDeferralOrderMutation,
    useGetDeferralOrderDetailMutation,
} from 'api/services/loan/serviceOrders'
import {
    useGetContractsToSignQuery,
    useLazyGetContractsToSignQuery,
    useSignDistanceAmendmentMutation,
} from 'api/services/customer/serviceContracts'
import { useEffect, useState } from 'react'
import { Contract } from 'api/contracts/types'
import { getIdentityStatus } from 'utils/getIdentityStatus'
import {
    setContractsProcess,
    setNotification,
    setPrePendingDeferralOrder,
} from 'store/stateReducer'
import { LoanStatus } from 'utils/loanStatus'
import { useNavigate } from 'react-router'

export const useDashboardLogic = () => {
    const Flags = useAppSelector((state) => state.app.flags)
    const dispatch = useDispatch()
    const activeLoanQuery = useGetActiveLoanQuery()
    const customerQuery = useGetCustomerQuery()
    const navigate = useNavigate()
    const [getLoanDetail, loanDetailQuery] = useGetLoanDetailMutation()
    const [getDeferralOrderDetail, deferralOrderQuery] = useGetDeferralOrderDetailMutation()
    const [createDeferralOrder] = useCreateDeferralOrderMutation()
    const [cancelDeferralOrder] = useCancelDeferralOrderMutation()
    const [signDistanceAmendment] = useSignDistanceAmendmentMutation()
    const contractsToSignQuery = useGetContractsToSignQuery()
    const [lazyContractsToSignQuery] = useLazyGetContractsToSignQuery()
    const [openPaymentModal, setOpenPaymentModal] = useState(false)
    const [openDeferralModal, setOpenDeferralModal] = useState(false)
    const [openCancellationModal, setOpenCancellationModal] = useState(false)
    const [showPaymentMethodForm, setShowPaymentMethodForm] = useState(false)
    const [showSignContractModal, setShowSignContractModal] = useState(false)
    const [showLoanDetailModal, setShowLoanDetailModal] = useState(false)
    const [showRentModal, setShowRentModal] = useState(false)
    const [closedSignContractModal, setClosedSignContractModal] = useState(false)
    const [openAmendmentModal, setOpenAmendmentModal] = useState(
        !customerQuery?.data?.distance_amendment_signed &&
            contractsToSignQuery?.data &&
            contractsToSignQuery?.data?.results?.filter((contract: Contract) => contract.type === 9)
                ?.length > 0,
    )
    const [openAmendmentRSModal, setOpenAmendmentRSModal] = useState(
        contractsToSignQuery?.data &&
            contractsToSignQuery?.data?.results?.filter(
                (contract: Contract) => contract.type === 12,
            )?.length > 0,
    )
    const [showPaymentForm, setShowPaymentForm] = useState<boolean>(true)
    const prePendingDeferralOrder = useAppSelector((state) => state.app.prePendingDeferralOrder)

    const handleDisableButtonLogic = () => {
        if (!customerQuery?.data) return

        return (
            getIdentityStatus(customerQuery?.data).status !== 'success' ||
            !Flags?.can_apply_for_loan
        )
    }

    const handleDeferral = async (days: number) => {
        try {
            await createDeferralOrder(days)
            await activeLoanQuery.refetch()
            await getDeferralOrderDetail(activeLoanQuery?.data?.id as number)
        } catch (error) {
            console.error('Dashboard handleDeferral:', error)
        }
    }

    const handlePayment = async () => {
        try{
            await activeLoanQuery.refetch()
            await customerQuery.refetch()
            await getDeferralOrderDetail(activeLoanQuery?.data?.id as number)
        } catch (error) {
            console.error('Dashboard handlePayment:', error)
        }
    }

    const handleCancellationDeferral = async () => {
        await cancelDeferralOrder(deferralOrderQuery?.data?.order_id as number)
        await activeLoanQuery.refetch()
        await getDeferralOrderDetail(activeLoanQuery?.data?.id as number)

        dispatch(setPrePendingDeferralOrder(null))

        // await ordersDeferralCancellation(deferral.order_id)
        // setDeferral(null)
        setOpenCancellationModal(false)
        setShowPaymentMethodForm(true)
    }

    const handlePayDeferral = () => {
        setOpenDeferralModal(true)
        setShowPaymentMethodForm(true)
        setShowPaymentForm(false)
    }

    const handleSignDistanceAmendment = async () => {
        await signDistanceAmendment()
        await customerQuery.refetch()
        await contractsToSignQuery.refetch()
        setOpenAmendmentModal(false)
        setOpenAmendmentRSModal(false)
    }

    const handleLoanDetail = async (id: number) => {
        await getLoanDetail(id)
        setShowLoanDetailModal(true)
    }

    useEffect(() => {
        if (prePendingDeferralOrder) {
            handleDeferral(prePendingDeferralOrder)
        }

        activeLoanQuery.refetch()
    }, [])

    useEffect(() => {
        if (!customerQuery?.data || !contractsToSignQuery?.data) return

        if (activeLoanQuery?.data) {
            (async () => {
                try {
                    await getDeferralOrderDetail(activeLoanQuery!.data!.id)
                } catch (err) {
                    console.log('err')
                }
            })()
        }
    }, [activeLoanQuery?.data, customerQuery?.data, contractsToSignQuery?.data])

    useEffect(() => {
        if (!contractsToSignQuery?.data) return

        if (
            contractsToSignQuery?.data?.results?.filter((contract: Contract) => contract.type !== 9)
                ?.length > 0
        ) {
            setShowSignContractModal(true)
        }

        if (
            contractsToSignQuery?.data?.results?.filter(
                (contract: Contract) => contract.type === 9 || contract.type === 12,
            )?.length > 0
        ) {
            setOpenAmendmentModal(true)
        }
    }, [])

    useEffect(() => {
        if (activeLoanQuery.data?.status === LoanStatus.PreApproved) {
            // handle case when user has to wait for op action
            dispatch(
                setContractsProcess({
                    type: 'OP',
                    message: 'Vyčkejte na převzetí žádosti administrátorem',
                }),
            )
        }

        if (activeLoanQuery?.data?.status === LoanStatus.Approved) {
            // handle case when user has to wait for generation of contracts to sign
            const contracts = contractsToSignQuery?.data?.results ?? []
            if (contracts.length === 0) {
                dispatch(
                    setContractsProcess({
                        type: 'GENERATION',
                        message: 'Generujeme smlouvy, malý moment',
                    }),
                )
            }

            if (contracts.length > 0) {
                if (
                    contracts.filter((contract) => contract.latest_signi_status === 'rejected')
                        .length > 0
                ) {
                    // 1) when contracts are rejected
                    dispatch(
                        setContractsProcess({
                            type: 'REJECTED',
                            message: 'Podpis Dokumentu byl odmítnutý',
                        }),
                    )
                    return
                }

                if (
                    contracts.filter(
                        (contract) => contract.latest_signi_status === 'client_pending',
                    ).length > 0
                ) {
                    // 2) when contracts are pending on client sign
                    dispatch(
                        setContractsProcess({
                            type: 'CLIENT_PENDING',
                            message: 'Čeká se na podpis klienta...',
                        }),
                    )
                }

                if (
                    contracts.filter((contract) => contract.latest_signi_status === 'vista_pending')
                        .length > 0
                ) {
                    // 2) when contracts are rejected
                    dispatch(
                        setContractsProcess({
                            type: 'VISTA_PENDING',
                            message: 'Čekejte prosím na podpis VistaCredit, s.r.o.',
                        }),
                    )
                }
            }
        }

        if (
            activeLoanQuery?.data?.status !== LoanStatus.Approved &&
            activeLoanQuery?.data?.status !== LoanStatus.PreApproved
        ) {
            // handle case when user signed generated contracts => reset contracts process
            dispatch(setContractsProcess({ type: 'NONE', message: '' }))
        }
    }, [activeLoanQuery?.data?.status, contractsToSignQuery?.data?.results])

    useEffect(() => {
        if (
            location.pathname.includes('rent') &&
            location.search.includes('?payment_status=PAID')
        ) {
            dispatch(
                setNotification({
                    type: 'success',
                    title: 'Hurááá',
                    message: 'Služba RENT byla aktivována',
                }),
            )
            customerQuery.refetch()
            navigate('/')
        }
    }, [location])

    return {
        dispatch,
        Flags,
        activeLoanQuery,
        customerQuery,
        getLoanDetail,
        loanDetailQuery,
        getDeferralOrderDetail,
        deferralOrderQuery,
        lazyContractsToSignQuery,
        contractsToSignQuery,
        showLoanDetailModal,
        showPaymentForm,
        showPaymentMethodForm,
        showRentModal,
        showSignContractModal,
        openPaymentModal,
        openAmendmentModal,
        openCancellationModal,
        openDeferralModal,
        closedSignContractModal,
        setShowLoanDetailModal,
        setOpenAmendmentModal,
        setClosedSignContractModal,
        setOpenPaymentModal,
        setOpenDeferralModal,
        setOpenCancellationModal,
        setShowPaymentForm,
        setShowPaymentMethodForm,
        setShowRentModal,
        setShowSignContractModal,
        setPrePendingDeferralOrder,
        prePendingDeferralOrder,
        handleDeferral,
        handlePayment,
        handleCancellationDeferral,
        handleDisableButtonLogic,
        handleLoanDetail,
        handlePayDeferral,
        handleSignDistanceAmendment,
        openAmendmentRSModal,
        setOpenAmendmentRSModal,
    }
}
